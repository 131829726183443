<template>
  <div>
    <v-card>
      <v-card-title>Inaccuracy Log
        <span class="font-weight-light ml-auto">
          Start Date: {{ formatDate(startDateFilter) }} &emsp; End Date: {{ formatDate(endDateFilter) }}
        </span>
      </v-card-title>
      <v-data-table
        ref="inaccuracyLog"
        fixed-header
        height="80vh"
        :loading="isLoading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="inaccuracyEntries"
        :expanded="inaccuracyEntries.EquipmentLogHistory"
        @click:row="(item, slot) => {slot.expand(!slot.isExpanded)}"
        @update:expanded="onRowClick"
        single-expand
        :items-per-page="50"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-options': [50, 100, -1],
        }"
        style="text-align: left"
      >
      <template v-slot:item.previous="{ item }">
          <tr>
            <td v-if="item.previousLocation">
              <span>Zone: {{ formatGroups(item.previousLocation.groups) }}</span>
              <br /><span>Spot: {{ item.previousLocation.displayName }}</span>
            </td>
          </tr>
          <tr>
            <td v-if="item.previousEquipment">
              <span v-if="item.previousEquipment.number != item.updatedEquipment.number">
                Number: {{ item.previousEquipment.number }}<br />
              </span>
              <span v-if="item.previousEquipment.type != item.updatedEquipment.type">
                Type: {{ item.previousEquipment.type }}<br />
              </span>
              <span v-if="item.previousEquipment.size != item.updatedEquipment.size">
                Size: {{ item.previousEquipment.size }}<br />
              </span>
              <span v-if="item.previousEquipment.state != item.updatedEquipment.state">
                State: {{ item.previousEquipment.state }}<br />
              </span>
              <span v-if="getScacOrCarrierName(item.previousEquipment.carrier)
              != getScacOrCarrierName(item.updatedEquipment.carrier)">
                SCAC/Carrier: {{ getScacOrCarrierName(item.previousEquipment.carrier) }}
              </span>
              <span v-if="item.previousEquipment.bookingNumber != item.updatedEquipment.bookingNumber">
                Booking #: {{item.previousEquipment.bookingNumber}}<br />
              </span>
              <span v-if="item.previousEquipment.customer.name != item.updatedEquipment.customer.name">
                Customer: {{item.previousEquipment.customer.name}}<br />
              </span>
            </td>
            <td v-else></td>
          </tr>
        </template>
        <template v-slot:item.updated="{ item }">
          <tr>
            <td v-if="item.updatedLocation">
              <span>Zone: {{ formatGroups(item.updatedLocation.groups) }}</span>
              <br /><span>Spot: {{ item.updatedLocation.displayName }}</span>
            </td>
          </tr>
          <tr>
            <td v-if="item.updatedEquipment">
              <span v-if="item.previousEquipment.number != item.updatedEquipment.number">
                Number: {{ item.updatedEquipment.number }}<br />
              </span>
              <span v-if="item.previousEquipment.type != item.updatedEquipment.type">
                Type: {{ item.updatedEquipment.type }}<br />
              </span>
              <span v-if="item.previousEquipment.size != item.updatedEquipment.size">
                Size: {{ item.updatedEquipment.size }}<br />
              </span>
              <span v-if="item.previousEquipment.state != item.updatedEquipment.state">
                State: {{ item.updatedEquipment.state }}<br />
              </span>
              <span v-if="getScacOrCarrierName(item.previousEquipment.carrier)
              != getScacOrCarrierName(item.updatedEquipment.carrier)"
              >
                SCAC/Carrier: {{ getScacOrCarrierName(item.updatedEquipment.carrier) }}
              </span>
              <span v-if="item.previousEquipment.bookingNumber != item.updatedEquipment.bookingNumber">
                Booking #: {{item.updatedEquipment.bookingNumber}}<br />
              </span>
              <span v-if="item.previousEquipment.customer.name != item.updatedEquipment.customer.name">
                Customer: {{item.updatedEquipment.customer.name}}<br />
              </span>
            </td>
            <td v-else></td>
          </tr>
        </template>
        <template
          v-slot:expanded-item="{ headers, item }"
          color="primary"
        >
          <td
            :colspan="headers.length"
            class="py-4"
            bgcolor="#EEEEEE"
          >
            <equipment-log-history id="history-log" ref="equipmentLogHistory" v-bind:logEntries=item.logHistory v-bind:condensed=true></equipment-log-history>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EquipmentLogHistory from './equipmentLogHistory.vue';

export default {
  props: ['inaccuracyEntries'],
  components: { EquipmentLogHistory },
  data: () => ({
    headers: [
      { text: 'YMS Action', value: 'type' },
      {
        text: 'Action Date/Time',
        value: 'formattedDate',
        sort(a, b) {
          const date1 = new Date(a);
          const date2 = new Date(b);
          return date1 - date2;
        },
      },
      { text: 'Equipment Number', value: 'equipment.number' },
      { text: 'Type', value: 'equipment.type' },
      { text: 'State', value: 'equipment.state' },
      { text: 'Previous', value: 'previous', sortable: false },
      { text: 'Updated', value: 'updated', sortable: false },
      { text: 'By', value: 'by' },
      { text: 'Gate In', value: 'gateInLogEntry.formattedDate' },
      { text: 'Gate In By', value: 'gateInLogEntry.by' },
    ],
  }),
  computed: {
    ...mapGetters('equipmentLog', ['isLoading', 'startDateFilter', 'endDateFilter']),
  },
  methods: {
    formatGroups(groups) {
      return groups.join(', ');
    },
    formatDate(date) {
      const formattedDate = date.toLocaleString({}, { timeZone: 'UTC' });
      return formattedDate.slice(0, formattedDate.indexOf(','));
    },
    scrollToTableBottom() {
      const { inaccuracyLog } = this.$refs;
      const scrollArea = inaccuracyLog.$el.querySelector('.v-data-table__wrapper');
      const expandedRow = inaccuracyLog.$el.querySelector('.v-data-table__expanded__row');
      const lastItemId = inaccuracyLog.items[inaccuracyLog.items.length - 1].id;

      if (expandedRow && inaccuracyLog.expansion[lastItemId]) {
        const rowTop = expandedRow.offsetTop - scrollArea.offsetTop;
        scrollArea.scrollTo({
          top: rowTop,
          behavior: 'smooth',
        });
      }
    },
    onRowClick() {
      this.$nextTick(() => {
        this.scrollToTableBottom();
      });
    },
  },
};
</script>

<style>
  #expanded-area {
    border-bottom: thin solid rgba(0, 0, 0, 0.15);
  }

  .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) >
  td:not(.v-data-table__mobile-row), .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:not(.v-data-table__mobile-row) {
    border-bottom: thin solid rgba(0, 0, 0, 0);
}

.v-data-table > .v-data-table__wrapper tbody tr.v-data-table__expanded {
    background-color: rgb(238, 238, 238);
  }
</style>
