<template>
  <div>
    <v-card>
      <v-card-title>
        Yard Accuracy Report
      </v-card-title>
      <v-row class="mx-2">
          <v-col cols="12">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates"
                  label="Filter By Date(s)"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                range
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Close
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="okClicked(dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="mx-2">
          <v-col>
            <v-card class="pt-4 text-center" height="15vw">
              <span class="font-weight-bold">Yard Accuracy %</span><br/>
              <v-progress-circular
                v-if="locationIsLoading"
                indeterminate
                size="120"
                class="mx-auto"
                style="overflow: hidden"
              ></v-progress-circular>
              <h1 id="accuracyPercentage" v-if="!locationIsLoading">{{ Math.round(((maxYardCapacity - inaccurateLocationsCount) / maxYardCapacity) * 100) }}</h1>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pt-4 text-center" height="15vw">
              <span class="font-weight-bold">Number of Inaccurate Locations</span><br/>
              <v-progress-circular
                v-if="locationIsLoading"
                indeterminate
                size="120"
                class="mx-auto"
                style="overflow: hidden"
              ></v-progress-circular>
              <h1 id="inaccurateLocationsCount" v-if="!locationIsLoading">{{ inaccurateLocationsCount }}</h1>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pt-4 text-center" height="15vw">
              <span class="font-weight-bold">Total Locations with Equipment</span><br />
              <v-progress-circular
                v-if="locationIsLoading"
                indeterminate
                size="120"
                class="offset-md-5 mx-auto"
                style="overflow: hidden"
              ></v-progress-circular>
              <h1 id="locationsWithEquipment" v-if="!locationIsLoading">{{ locationsWithEquipment.length }}</h1>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="pt-4 text-center" height="15vw">
              <span class="font-weight-bold">Max Yard Capacity</span><br/>
              <v-progress-circular
                v-if="locationIsLoading"
                indeterminate
                size="120"
                class="mx-auto"
                style="overflow: hidden"
              ></v-progress-circular>
              <h1 id="maximumYardCapacity" v-if="!locationIsLoading">{{ maxYardCapacity }}</h1>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mx-2">
          <v-col>
            <yard-inaccuracies :inaccuracyEntries="inaccuracies"></yard-inaccuracies>
          </v-col>
        </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import YardInaccuracies from './yardInaccuracies.vue';

export default {
  components: { YardInaccuracies },
  data: () => ({
    menu: false,
    dates: [],
  }),
  computed: {
    ...mapGetters('equipmentLog', [
      'inaccuracies',
      'inaccurateLocationsCount',
    ]),
    ...mapGetters('location', ['locations', 'locationsWithEquipment']),
    ...mapGetters({
      locationIsLoading: 'location/isLoading',
    }),
    maxYardCapacity() {
      let totalCapacity = 0;
      for (let i = 0; i <= this.locations.length; i += 1) {
        if (this.locations[i]) {
          totalCapacity += this.locations[i].equipmentCapacity;
        }
      }
      return totalCapacity;
    },
  },
  methods: {
    ...mapActions('equipmentLog', ['setStartDateFilter', 'setEndDateFilter', 'getInaccuracies']),
    async okClicked(value) {
      if (value[1]) {
        if (value[1] > value[0]) {
          this.setStartDateFilter(value[0]);
          this.setEndDateFilter(value[1]);
        } else {
          this.setStartDateFilter(value[1]);
          this.setEndDateFilter(value[0]);
        }
      } else {
        this.setStartDateFilter(value[0]);
        this.setEndDateFilter(value[0]);
      }
      await this.getInaccuracies();
      this.menu = false;
    },
  },
  async created() {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    this.setStartDateFilter(yesterday.toISOString());
    this.setEndDateFilter(yesterday.toISOString());
    await this.getInaccuracies();
  },
};
</script>

<style>
#accuracyPercentage, #maximumYardCapacity, #inaccurateLocationsCount, #locationsWithEquipment {
  font-size:12vw;
  line-height: 12vw;
  height: 12vw;
}
</style>
