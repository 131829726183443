<template>
  <div>
    <yard-accuracy-report></yard-accuracy-report>
  </div>
</template>

<script>
import YardAccuracyReport from '../components/yardAccuracyReport.vue';

export default {
  name: 'YardAccuracyReportView',
  components: { YardAccuracyReport },
};
</script>
