var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Inaccuracy Log "),_c('span',{staticClass:"font-weight-light ml-auto"},[_vm._v(" Start Date: "+_vm._s(_vm.formatDate(_vm.startDateFilter))+"   End Date: "+_vm._s(_vm.formatDate(_vm.endDateFilter))+" ")])]),_c('v-data-table',{ref:"inaccuracyLog",staticStyle:{"text-align":"left"},attrs:{"fixed-header":"","height":"80vh","loading":_vm.isLoading,"loading-text":"Loading... Please wait","headers":_vm.headers,"items":_vm.inaccuracyEntries,"expanded":_vm.inaccuracyEntries.EquipmentLogHistory,"single-expand":"","items-per-page":50,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-options': [50, 100, -1],
      }},on:{"click:row":function (item, slot) {slot.expand(!slot.isExpanded)},"update:expanded":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.previous",fn:function(ref){
      var item = ref.item;
return [_c('tr',[(item.previousLocation)?_c('td',[_c('span',[_vm._v("Zone: "+_vm._s(_vm.formatGroups(item.previousLocation.groups)))]),_c('br'),_c('span',[_vm._v("Spot: "+_vm._s(item.previousLocation.displayName))])]):_vm._e()]),_c('tr',[(item.previousEquipment)?_c('td',[(item.previousEquipment.number != item.updatedEquipment.number)?_c('span',[_vm._v(" Number: "+_vm._s(item.previousEquipment.number)),_c('br')]):_vm._e(),(item.previousEquipment.type != item.updatedEquipment.type)?_c('span',[_vm._v(" Type: "+_vm._s(item.previousEquipment.type)),_c('br')]):_vm._e(),(item.previousEquipment.size != item.updatedEquipment.size)?_c('span',[_vm._v(" Size: "+_vm._s(item.previousEquipment.size)),_c('br')]):_vm._e(),(item.previousEquipment.state != item.updatedEquipment.state)?_c('span',[_vm._v(" State: "+_vm._s(item.previousEquipment.state)),_c('br')]):_vm._e(),(_vm.getScacOrCarrierName(item.previousEquipment.carrier)
            != _vm.getScacOrCarrierName(item.updatedEquipment.carrier))?_c('span',[_vm._v(" SCAC/Carrier: "+_vm._s(_vm.getScacOrCarrierName(item.previousEquipment.carrier))+" ")]):_vm._e(),(item.previousEquipment.bookingNumber != item.updatedEquipment.bookingNumber)?_c('span',[_vm._v(" Booking #: "+_vm._s(item.previousEquipment.bookingNumber)),_c('br')]):_vm._e(),(item.previousEquipment.customer.name != item.updatedEquipment.customer.name)?_c('span',[_vm._v(" Customer: "+_vm._s(item.previousEquipment.customer.name)),_c('br')]):_vm._e()]):_c('td')])]}},{key:"item.updated",fn:function(ref){
            var item = ref.item;
return [_c('tr',[(item.updatedLocation)?_c('td',[_c('span',[_vm._v("Zone: "+_vm._s(_vm.formatGroups(item.updatedLocation.groups)))]),_c('br'),_c('span',[_vm._v("Spot: "+_vm._s(item.updatedLocation.displayName))])]):_vm._e()]),_c('tr',[(item.updatedEquipment)?_c('td',[(item.previousEquipment.number != item.updatedEquipment.number)?_c('span',[_vm._v(" Number: "+_vm._s(item.updatedEquipment.number)),_c('br')]):_vm._e(),(item.previousEquipment.type != item.updatedEquipment.type)?_c('span',[_vm._v(" Type: "+_vm._s(item.updatedEquipment.type)),_c('br')]):_vm._e(),(item.previousEquipment.size != item.updatedEquipment.size)?_c('span',[_vm._v(" Size: "+_vm._s(item.updatedEquipment.size)),_c('br')]):_vm._e(),(item.previousEquipment.state != item.updatedEquipment.state)?_c('span',[_vm._v(" State: "+_vm._s(item.updatedEquipment.state)),_c('br')]):_vm._e(),(_vm.getScacOrCarrierName(item.previousEquipment.carrier)
            != _vm.getScacOrCarrierName(item.updatedEquipment.carrier))?_c('span',[_vm._v(" SCAC/Carrier: "+_vm._s(_vm.getScacOrCarrierName(item.updatedEquipment.carrier))+" ")]):_vm._e(),(item.previousEquipment.bookingNumber != item.updatedEquipment.bookingNumber)?_c('span',[_vm._v(" Booking #: "+_vm._s(item.updatedEquipment.bookingNumber)),_c('br')]):_vm._e(),(item.previousEquipment.customer.name != item.updatedEquipment.customer.name)?_c('span',[_vm._v(" Customer: "+_vm._s(item.updatedEquipment.customer.name)),_c('br')]):_vm._e()]):_c('td')])]}},{key:"expanded-item",fn:function(ref){
            var headers = ref.headers;
            var item = ref.item;
return [_c('td',{staticClass:"py-4",attrs:{"colspan":headers.length,"bgcolor":"#EEEEEE"}},[_c('equipment-log-history',{ref:"equipmentLogHistory",attrs:{"id":"history-log","logEntries":item.logHistory,"condensed":true}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }